import { render, staticRenderFns } from "./CreateNewAccount.vue?vue&type=template&id=53f16bca&scoped=true&"
import script from "./CreateNewAccount.vue?vue&type=script&lang=js&"
export * from "./CreateNewAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f16bca",
  null
  
)

export default component.exports