<template>
    <section class="pt-lg-8 pt-4 place-holder-white">
        <div class="container full-height pt-lg-4 pt-8">

            <login-heading heading="Create New Account" size="lg">

                <template #prepend>
                    <span class="text-2 font-inter-bold fs--1 mb-4">START FOR FREE</span>
                </template>

                <template #info>
                    <p class="d-inline mr-1">Already have an account ?</p>
                    <span class="text-secondary"><router-link class="text-decoration-none"
                                                              to="/login/">Sign In</router-link></span>
                </template>

            </login-heading>

            <transition name="fade" mode="out-in">

                <register-mobile-authentication
                    v-if="currentSection === 'mobile-verification'" key="mobile-verification"
                    @verify-success="setVerifySection"/>

                <div v-else-if="currentSection === 'register-types'" key="register-types">

                    <s-form class="row">
                        <div class="col-12 col-lg-4 col-md-6">

                            <validated-select class="c-input-select-b bg-blue place-holder-white"
                                              placeholder="Please choose registration type"
                                              v-model="model.option" :options="options"></validated-select>
                        </div>
                    </s-form>

                    <individual-registration :details="setModel" :model="ModelData" @on-submit="IndividualRegistration"
                                             v-if="model.option === 'individual'"/>

                    <group-of-individuals-registration :data="[...groupIndividuals]" @onUpdate="groupModelUpdate"
                                                       @addRow="addRow" @deleteRow="deleteRow" :details="setModel"
                                                       @submitSuccess="GroupOfIndividualsRegistration"
                                                       v-else-if="model.option === 'group-ind'"/>

                    <proprietor-registration :details="setModel" @on-submit="ProprietorRegistration"
                                             v-else-if="model.option === 'proprietor'"/>

                    <partnership-registration :details="setModel" @on-submit="PartnershipRegistration"
                                              v-else-if="model.option === 'partnership'"/>

                    <llp-registration :details="setModel" @on-submit="LLPRegistration"
                                      v-else-if="model.option === 'llp'"/>

                    <private-limited-registration @on-submit="PrivateLimitedRegistration" :details="setModel"
                                                  v-else-if="model.option === 'private-ltd'"/>

                    <society-registration :details="setModel" @on-submit="SocietyRegistration"
                                          v-else-if="model.option === 'society'"/>

                    <btn text="Back" class="mt-4 auth-button col-2 col-lg-1  border-r--2"
                         @click="currentSection = 'mobile-verification'"/>
                </div>

                <set-email-and-password :type="RegisterType" :data="ModelData"
                                        v-else-if="currentSection ==='set-email'"
                                        @goBack="currentSection = 'register-types'"></set-email-and-password>
            </transition>

        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';
import IndividualRegistration from '../../../views/auth/register/IndividualRegistration';
import GroupOfIndividualsRegistration from '../../../views/auth/register/GroupOfIndividualsRegistration';
import SetEmailAndPassword from '../../../views/auth/register/SetEmailAndPassword';
import ProprietorRegistration from '../../../views/auth/register/ProprietorRegistration';
import PartnershipRegistration from '../../../views/auth/register/PartnershipRegistraion';
import LlpRegistration from '../../../views/auth/register/LlpRegistration';
import PrivateLimitedRegistration from '../../../views/auth/register/PrivateLimitedRegistration';
import SocietyRegistration from '../../../views/auth/register/SocietyRegistration';
import RegisterMobileAuthentication from '../../../views/auth/register/RegisterMobileAuthentication';

export default {
    name: 'CreateNewAccount',

    components: {
        RegisterMobileAuthentication,
        SocietyRegistration,
        PrivateLimitedRegistration,
        LlpRegistration,
        PartnershipRegistration,
        ProprietorRegistration,
        SetEmailAndPassword,
        GroupOfIndividualsRegistration,
        IndividualRegistration,
        LoginHeading
    },

    data () {
        return {
            ModelData: null,
            RegisterType: '',
            currentSection: 'mobile-verification',

            model: {
                option: ''
            },

            options: [
                {
                    label: 'Individual',
                    value: 'individual'

                },
                {
                    label: 'Group of Individuals',
                    value: 'group-ind'
                },
                {
                    label: 'Proprietor',
                    value: 'proprietor'
                },
                {
                    label: 'Partnership',
                    value: 'partnership'
                },
                {
                    label: 'LLP',
                    value: 'llp'
                },
                {
                    label: 'Private limited/ limited ',
                    value: 'private-ltd'
                },
                {
                    label: 'Society/trust',
                    value: 'society'
                }
            ],

            groupIndividuals: [{
                first_name: '',
                last_name: '',
                father_husband_name: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: '',
                pan: ''
            }],

            setModel: null
        };
    },

    methods: {
        setVerifySection (model) {
            this.setModel = { ...model };
            this.currentSection = 'register-types';
        },

        IndividualRegistration (data) {
            const model = data;
            this.RegisterType = 'individual';
            console.log('model - data', data);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        GroupOfIndividualsRegistration (groupIndividuals) {
            this.RegisterType = 'group-ind';
            this.ModelData = [...groupIndividuals];
            this.currentSection = 'set-email';
        },

        ProprietorRegistration (data) {
            const model = data;
            this.RegisterType = 'proprietor';
            console.log('model - data', model);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        PartnershipRegistration (data) {
            const model = data;
            this.RegisterType = 'partnership';
            console.log('model - data', model);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        LLPRegistration (data) {
            const model = data;
            this.RegisterType = 'llp';
            console.log('model - data', model);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        PrivateLimitedRegistration (data) {
            const model = data;
            this.RegisterType = 'private-ltd';
            console.log('model - data', model);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        SocietyRegistration (data) {
            const model = data;
            this.RegisterType = 'society';
            console.log('model - data', model);
            this.ModelData = model;
            this.currentSection = 'set-email';
        },

        groupModelUpdate (key, value, index) {
            console.log('key : ', key);
            console.log('index : ', index);
            console.log('value : ', value);
            this.groupIndividuals[index][key] = value;
        },

        addRow () {
            this.groupIndividuals.push({
                first_name: '',
                last_name: '',
                father_husband_name: '',
                mobile_number: '',
                address: '',
                country: '',
                state: '',
                district: '',
                pin_code: ''
            });
        },

        deleteRow (index) {
            this.groupIndividuals.splice(index, 1);
        }
    }
};
</script>

<style scoped>

</style>
