<template>
    <div>
        <s-form class="row" @submit="onSubmit" ref="formValidator">

            <div class="col-12 col-lg-4 col-md-6">
                <validated-input label="Email*" type="email" class="c-input"
                                 v-model="model.email_id" name="Email Id" @input="resetVerification"
                                 @blur="resetVerification" :disabled="loading|| sendingEmailOTP || verifyOTP">
                    <template #append>
                        <btn v-if="!showOTPSection" :disabled="!isEmailValid || !model.email_id"
                             text="Verify E-Mail" class="auth-button"
                             type="button" @click="sendOTPVerification" :loading="sendingEmailOTP"
                             loading-text="Verifying..."/>
                        <btn v-if="isValidEmail" text="Verified" type="button" color="success"/>
                    </template>
                </validated-input>
                <p class="mb-0 text-danger" v-if="!showOTPSection && showErrorMessage">{{ errorMessage }}</p>

                <div v-if="showOTPSection && !isValidEmail">
                    <validated-input :disabled="loading || sendingEmailOTP || verifyOTP" v-model="verify.otp"
                                     type="number" @input="clearMessages"
                                     label="Please enter the One-Time Password (OTP) sent to the provided email address."
                                     name="OTP"
                                     class="c-input" :rules="{min:6, max:6}">
                        <template #append>
                            <btn :loading="verifyOTP" loading-text="Verifying..."
                                 :disabled="!isEmailValid || !model.email_id || sendingEmailOTP || verifyOTP"
                                 text="Verify OTP" class="auth-button"
                                 type="button" @click="verifyEmail"/>
                        </template>
                    </validated-input>

                    <p class="mb-0 text-danger" v-if="showErrorMessage">{{ errorMessage }}</p>
                    <p v-if="isInvalidOtp" class="text-danger">Invalid OTP</p>
                    <div class="fl-x fl-j-e">
                        <span @click="resendOtp" class="font-inter-extra-light text-white fs--1 cursor-pointer">
                            Not yet received ? <span class=""><u>Resend</u></span>
                        </span>
                    </div>
                    <p v-if="!resendLoading && resendOtpMessage!==''"
                       class="font-inter-extra-light text-white fs--1 mt-4">
                        {{ resendOtpMessage }}</p>
                </div>

                <validated-input label="Password*" name="Password" type="password" class="c-input"
                                 v-model="model.password"></validated-input>
                <p class="mt-3 mb-3 text-3 fs--1">Password must contain at least 8 characters, including upper &
                    lower case letters, numbers and special characters.</p>
                <p v-if="showError" class="text-danger">{{ errorMessage }}</p>
            </div>
            <div class="col-12">
                <div class="fl-x">
                    <btn text="Back" type="button" class="mr-2 px-5" @click="$emit('goBack')"/>
                    <btn :disabled="loading || !isValidEmail" text="Submit" class="px-5" :loading="loading"
                         loading-text="Submitting..." type="submit"></btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'SetEmailAndPassword',

    props: {
        type: { type: String },

        data: { type: Object }
    },

    computed: {
        isEmailValid () {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(this.model.email_id);
        }
    },

    data () {
        return {
            errorMessage: '',
            showError: false,
            loading: false,

            model: {
                email_id: '',
                password: ''
            },
            verify: {
                otp: ''
            },

            showOTPSection: false,
            sendingEmailOTP: false,
            verifyOTP: false,

            isInvalidOtp: false,
            showErrorMessage: false,

            resendLoading: false,
            resendOtpMessage: '',

            isValidEmail: false
        };
    },

    methods: {
        async sendOTPVerification () {
            if (!this.model.email_id) {
                this.$notify('Please enter a valid E-Mail ID', 'Error', {
                    type: 'danger'
                });
                return;
            }
            await this.getOtp();
        },
        async getOtp () {
            this.verify.otp = '';
            this.sendingEmailOTP = true;
            const response = await axios.form(urls.auth.OTPSendToEmail, this.model);
            if (response.data.success) {
                this.showOTPSection = true;
            } else {
                this.showErrorMessage = true;
                this.errorMessage = response.data.message;
            }
            this.sendingEmailOTP = false;
        },
        async resendOtp () {
            this.errorMessage = '';
            this.isInvalidOtp = false;
            this.resendLoading = true;
            const response = await axios.get(urls.auth.resendEmailOtp, {
                params:
                    { email_id: this.model.email_id }
            });
            const json = response.data;
            if (json.error === false) {
                this.resendOtpMessage = 'OTP has been successfully send to ' + this.model.email_id + '.';
            } else {
                this.resendOtpMessage = 'Could not resend OTP, Please try again.';
            }
            this.resendLoading = false;
        },
        async verifyEmail () {
            this.isValidEmail = false;
            this.showErrorMessage = false;
            this.verifyOTP = true;
            const verify = {
                email_id: this.model.email_id,
                otp: this.verify.otp
            };
            const response = await axios.form(urls.auth.EmailOTPVerify, verify);
            if (response.data.success) {
                this.isValidEmail = true;
            } else {
                this.isValidEmail = false;
                this.showErrorMessage = true;
                this.errorMessage = response.data.message;
            }
            this.verifyOTP = false;
        },
        resetVerification () {
            this.errorMessage = '';
            this.resendOtpMessage = '';
            this.verify.otp = '';
            this.showOTPSection = false;
            this.isValidEmail = false;
        },
        clearMessages () {
            this.errorMessage = '';
            this.resendOtpMessage = '';
        },
        async onSubmit () {
            this.loading = true;
            if (this.type === 'individual') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.individual, model);
                console.log('individual-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'group-ind') {
                const first = this.data.shift();

                const model = {
                    ...this.model,
                    ...first,
                    other_individuals: JSON.stringify([...this.data])
                };

                const response = await axios.form(urls.register.groupIndividual, model);
                console.log('groupIndividual-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'proprietor') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.proprietor, model);
                console.log('proprietor-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'partnership') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.partnership, model);
                console.log('partnership-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'llp') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.llp, model);
                console.log('llp-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'private-ltd') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.private, model);
                console.log('private-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }

            if (this.type === 'society') {
                const model = {
                    ...this.data,
                    ...this.model
                };
                const response = await axios.form(urls.register.society, model);
                console.log('society-response', response);
                if (response.data.success_reg) {
                    await this.$router.push({ name: 'RegisterSuccessful' });
                } else {
                    this.$refs.formValidator.setErrors(response.data.errors);
                    this.errorMessage = response.data.message;
                    this.showError = true;
                    this.loading = true;
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
